import React, { useEffect, useState, useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BEMHelper from 'react-bem-helper'
import BlockContent from '@sanity/block-content-to-react'
import useWindowSize from '../hooks/useWindowSize'
import { ThemeContext } from '../components/Layout/Layout'
import Slideshow from '../components/Slideshow/Slideshow'
import Manifesto from '../components/Manifesto'
import ContactList from '../components/ContactList'

import './index.scss'

const bem = new BEMHelper({ name: 'home-page' })

const HomePage = () => {
  const {
    sanitySiteSettings: {
      _rawDescription: description,
      _rawManifesto: manifesto,
    },
  } = useStaticQuery(graphql`
    query {
      sanitySiteSettings {
        _rawDescription
        _rawManifesto
      }
    }
  `)

  const width = useWindowSize()[0]
  const isMobile = width < 1024

  const { whiteText } = useContext(ThemeContext)

  const [showManifesto, setShowManifesto] = useState(false)
  const [showSlideshow, setShowSlideshow] = useState(false)

  useEffect(() => {
    if (isMobile) {
      return
    }

    const handleMouseMove = (e) => {
      const mouseOnLeft = e.clientX <= width / 2
      setShowSlideshow(mouseOnLeft)
    }

    document.addEventListener('mousemove', handleMouseMove)

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
    }
  }, [width, isMobile])

  return (
    <div {...bem()}>
      {!isMobile && showSlideshow && <Slideshow />}
      <div
        {...bem(
          'left',
          isMobile && showManifesto ? 'hidden' : '',
          whiteText ? 'white-text' : ''
        )}
      >
        <BlockContent blocks={description} />
        <div {...bem('manifesto-cta', showManifesto ? 'hidden' : '')}>
          <button onClick={() => setShowManifesto(true)}>(manifesto)</button>
        </div>
        <ContactList />
      </div>
      <div {...bem('right', showManifesto ? '' : 'hidden')}>
        <Manifesto
          manifesto={manifesto}
          isHidden={!showManifesto}
          setShowManifesto={setShowManifesto}
        />
      </div>
    </div>
  )
}

export default HomePage
