import React, { useEffect, useRef } from 'react'
import BEMHelper from 'react-bem-helper'
import BlockContent from '@sanity/block-content-to-react'

import './Manifesto.scss'

const bem = new BEMHelper({ name: 'manifesto' })

const Manifesto = ({ manifesto, isHidden, setShowManifesto }) => {
  const ref = useRef()

  useEffect(() => {
    if (!isHidden) {
      ref.current.scrollIntoView()
    }
  }, [isHidden])

  return (
    <div {...bem(null, isHidden ? 'hidden' : '')} ref={ref}>
      <BlockContent blocks={manifesto} />
      <button {...bem('btn-close')} onClick={() => setShowManifesto(false)}>
        (close)
      </button>
    </div>
  )
}

export default Manifesto
