import React, { useState, useEffect, useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import BEMHelper from 'react-bem-helper'
import { ThemeContext } from '../Layout/Layout'

import './Slideshow.scss'

const bem = new BEMHelper({ name: 'slideshow' })

const Slideshow = () => {
  const { queryImages } = useStaticQuery(graphql`
    query {
      queryImages: allSanityHomepageBackgrounds {
        edges {
          node {
            artistsBackgrounds {
              asset {
                gatsbyImageData(layout: FULL_WIDTH)
              }
              whiteText
            }
          }
        }
      }
    }
  `)

  const images = queryImages.edges[0].node.artistsBackgrounds

  const { setWhiteText } = useContext(ThemeContext)
  const [count, setCount] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCount(Math.floor(Math.random() * images.length))
    }, 3000)

    return () => clearInterval(interval)
  }, [images.length])

  useEffect(() => {
    const whiteText = images[count].whiteText
    setWhiteText(whiteText)
  }, [count, images, setWhiteText])

  useEffect(() => () => setWhiteText(false), [setWhiteText])

  if (!images[count]?.asset?.gatsbyImageData) {
    return null
  }

  return (
    <div {...bem()}>
      <GatsbyImage image={images[count].asset.gatsbyImageData} />
    </div>
  )
}

export default Slideshow
