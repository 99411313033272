import React from 'react'
import BEMHelper from 'react-bem-helper'
import { useStaticQuery, graphql } from 'gatsby'
import useWindowSize from '../../hooks/useWindowSize'

import './ContactList.scss'

const bem = new BEMHelper({ name: 'contact-list' })

const ContactList = () => {
  const {
    sanitySiteSettings: { phoneNumbers, email, socialMedia, addresses },
  } = useStaticQuery(graphql`
    query {
      sanitySiteSettings {
        addresses {
          zipCode
          street
          city
        }
        email
        phoneNumbers {
          number
          label
        }
        socialMedia {
          label
          link
          name
        }
      }
    }
  `)

  const width = useWindowSize()[0]
  const isMobile = width < 1024

  return (
    <ul {...bem()}>
      {email.map((email) => (
        <li {...bem('email')} key={email}>
          <a href={`mailto:${email}`}>{email}</a>
        </li>
      ))}
      {socialMedia.map(({ label, name, link }) => (
        <li {...bem('social')} key={name}>
          <a href={link} rel="noopener noreferrer" target="_blank">
            <span {...bem('social-label')}>{label}</span>
            &nbsp;
            {name}
          </a>
        </li>
      ))}
      {phoneNumbers.map(({ label, number }) => (
        <li {...bem('phone')} key={number}>
          <span {...bem('phone-label')}>{label}</span>
          {number}
        </li>
      ))}
      {addresses.map(({ street, city, zipCode }, idx) => (
        <li {...bem('address')} key={`${street}${idx}`}>
          {street}
          {isMobile ? <br></br> : <span>&nbsp;&ndash;&nbsp;</span>}
          {zipCode} {city}
        </li>
      ))}
    </ul>
  )
}

export default ContactList
